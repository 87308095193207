.App {
  height: 100%
}

.screenImage {
  background-image:  url("./assets/Logo.svg"), url("./assets/Website.jpg");
  background-position: 10% 10%, center;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;
  position: relative;

}

@media screen and (max-width: 899px) {
  .screenImage {
    height: 45%;
    min-height: 370px;
    background-position: 7% 7%, center;
    background-image:  url("./assets/SmallLogo.svg"), url("./assets/Website.jpg");
  }
}
